<template>
	<div class="login-page">
		<top :bgWhite='true'></top>
		<img src="../../assets/image/home/login_bg.png" class="bg-img">
		<div class="l-content">
			<img src="../../assets/image/login_pc.png" class="width228 height56 margin-bottom-15">
			<div class="login flex align-center flex-direction">
				<div class="login-box">
					<div class="login-header flex align-center">
						<div class="padding-bottom-10 point margin-right-40 bold fs20" @click="changeLoginType($event)"
							data-logintype="0">绑定手机号</div>

					</div>
					<!-- 账号登录 -->
					<div class="padding-top-20">
						<div :class="['item', 'account', errorTips == 3 ? 'msg-error' : '']"
							class="padding-lr-15 margin-bottom-20 flex align-center">
							<img src="../../assets/image/phone.png" class="width16 height20 margin-right-10">
							<input type="text" @keyup.enter="login" class="input" v-model="mobile"
								placeholder="请输入手机号" />
							<div class="cancel" @click="clearInput($event)" data-type="mobile"></div>
						</div>
						<div :class="['item', 'code', errorTips == 2 ? 'msg-error' : '']"
							class="padding-lr-15 flex align-center justify-between margin-bottom-20">
							<div class="flex align-center">
								<img src="../../assets/image/code.png" class="width16 height20 margin-right-10">
								<input type="number" class="input" v-model="validCode" @keyup.enter="login"
									placeholder="请输入验证码" maxlength="6" />
							</div>
							<span class="send-code point" v-show="show" @click="getCode">获取验证码</span>
							<span v-show="!show" class="send-code" style="cursor: not-allowed;">{{ count }} s</span>
						</div>
						<div :class="['item', 'password', errorTips == 4 ? 'msg-error' : '']"
							class="padding-lr-15 flex align-center margin-bottom-20">
							<img src="../../assets/image/pass.png" class="width16 height20 margin-right-10">
							<input type="password" class="input" v-model="password" @keyup.enter="login"
								placeholder="请输入密码(6-12位字母+数字)" />
							<div class="cancel" @click="clearInput($event)" data-type="password"></div>
						</div>
						<div :class="['item', 'password', errorTips == 4 ? 'msg-error' : '']"
							class="padding-lr-15 flex align-center">
							<img src="../../assets/image/pass.png" class="width16 height20 margin-right-10">
							<input type="password" class="input" v-model="newPassword" @keyup.enter="login"
								placeholder="请再次输入密码(6-12位字母+数字)" />
							<div class="cancel" @click="clearInput($event)" data-type="newPassword"></div>
						</div>
						<div class="login-button text-white text-center margin-tb-30 point" @click="login">确认</div>

						<!-- <div class="agree-box text-999 text-center ">
							<input type="checkbox" @click="checkAgree"
								:class="['checkbox', isAgree ? 'checked' : 'default']" class="checkbox default" />
							<span class="text">
								阅读并同意
								<span class="agreement point" @click="toTermsPage(15)">《用户注册协议》</span>
								和
								<span class="agreement point" @click="toTermsPage(23)">《隐私政策》</span>
							</span>
						</div> -->
					</div>
					<!-- /账号登录 -->

				</div>
			</div>
		</div>
		<div class="mask" @click="dialogTableVisible=false" v-if="dialogTableVisible"></div>
		<div class="box-content bg-white radius-3 padding-10" v-if="dialogTableVisible">
			<div class="flex align-center justify-between" style="position: relative;">
				<span class="bold fs20  text-con">{{contentTitle}}</span>
				<img src="../../assets/image/bg_con.png" class="bg-con">
				<i class="el-icon-close" @click="dialogTableVisible=false"></i>
			</div>
			<div class="content margin-top-10 fs14">
				用户注册协议
				{{content}}
			</div>
		</div>
	</div>
</template>

<script>
	import top from '@/components/top'
	import Cookie from 'js-cookie'
	import {
		validate
	} from '../../assets/js/validate';
	export default {
		components: {
			top
		},
		name: 'bind_phone',
		data() {
			return {
				mobile: '', //手机号
				dialogTableVisible: false, //是否展示协议弹框
				contentTitle: '用户注册协议', //协议标题
				validCode: '',
				content: '', //隐私协议内容
				userName: '',
				password: '',
				errorTips: 0, // 输入错误提示:  1手机号输入错误  2验证码输入错误  3账号输入错误  4密码输入错误 5图形验证码错误 6未同意条款
				// 验证码相关
				show: true, //是否展示倒计时
				count: '', //倒计时
				loginType: 0, //错误提示
				newPassword: '', //确认密码
				timer: null,
				hadGotCode: false,
				isAgree: false, // 是否同意条款
				isLogging: false, //是否正在登录
			}
		},
		created() {},
		methods: {
			/**
			 * 切换登录方式
			 */
			changeLoginType(e) {
				this.loginType = e.target.dataset.logintype
				this.mobile = ''
				this.validCode = ''
				this.password = ''
			},
			/**
			 * 获取登录验证码
			 */
			getCode() {
				if (!this.mobile) {
					this.$message.error('请输入手机号');
					return false;
				}
				if (!validate(this.mobile, 'phone')) {
					this.$message.error('请正确输入手机号');
					return false;
				}
				if (this.hadGotCode) {
					return
				}
				let sendSmsParam = {
					mobile: this.mobile,
					is_test: this.$store.state.is_dev,
					type: '5',
				}
				this.ajax('post', '/v1/5b5bdc44796e8', sendSmsParam, res => {
					console.log(res);
					this.hadGotCode = true
					if (res.code == 1) {
						const timeCount = 60;
						if (!this.timer) {
							this.count = timeCount;
							this.show = false;
							this.validCode = res.data.code
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= timeCount) {
									this.count--;
								} else {
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
									this.hadGotCode = false
								}
							}, 1000)
						}
					} else {
						this.hadGotCode = false;
						this.$message.error(res.msg);
					}

				}, err => {
					console.log(err);
					this.hadGotCode = false
				})
			},

			/**
			 * 注册
			 */
			login() {
				let data = ''
				// 手机号不存在
				if (!this.mobile) {
					this.$message.error('请输入手机号');
					return false;
				}
				// 手机验证码登录
				if (!validate(this.mobile, 'phone')) {
					this.$message.error('请输入正确的手机号');
					return
				}
				if (!this.validCode) {
					this.$message.error('请输入验证码');
					return
				}
				if (!this.$route.query.openid) {
					this.$message.error('登录信息已过期，请重试');
					return
				}
				data = {
					unionId:this.$route.query.unionid,
					nickName:this.$route.query.nickName || '',
					gender:'',
					province:'',
					avatarUrl:this.$route.query.avatarUrl || '',
					city:'',
					unionId:this.$route.query.unionid,
					mobile: this.mobile,
					code: this.validCode,
					type:5,
					client_id:0,
					openId: this.$route.query.openid,
					password:this.password,
					invite_code:'',
					bind_type:2, //1：app绑定2：网页应用绑定
					gzh_openid: this.$route.query.openid //网页版openid
				}
				// if (!this.isAgree) {
				// 	this.$message.error('请阅读并同意下方协议');
				// 	return
				// }
				if (this.isLogging) {
					return
				} else {
					this.ajax('post', '/v1/5d7757d28d076', data, res => {
						console.log(res.data, 'res');
						this.isLogging = true
						if (res.code == 1) {
							data = res.data
							this.isLogging = false
							localStorage.setItem('USER_INFO', JSON.stringify(res.data.userinfo))
							localStorage.setItem('user_token', res.data.userinfo.user_token)
							this.$store.commit('updateUserInfo', res.data.userinfo);
							this.$message({
								message: res.msg,
								type: 'success'
							});
							this.$router.push({
								path: '/'
							})
						} else {
							this.$message.error(res.msg);
							this.isLogging = false
						}
					}, err => {
						console.log(err);
						this.isLogging = false
					})
				}
			},
			/**
			 * 清除输入框内容
			 */
			clearInput(e) {
				const type = e.target.dataset.type
				switch (type) {
					case 'mobile':
						this.mobile = ''
						break;
					case 'userName':
						this.userName = ''
						break;
					case 'password':
						this.password = ''
						break;
					default:
						break;
				}
			},
			/**
			 * 同意条款
			 */
			checkAgree() {
				this.isAgree = !this.isAgree
			},

			/**
			 * 跳转到《服务条款》或《隐私协议》
			 */
			toTermsPage(sts) {
				this.dialogTableVisible = true
				if (sts == 15) {
					this.contentTitle = '用户注册协议'
				} else {
					this.contentTitle = '隐私政策'
				}
				this.ajax('post', '/v1/5d63befcb25d9', {
					category_id: sts,
				}, res => {
					console.log(res.data, 'res');
					if (res.code == 1) {
						this.content = res.data.content
						this.dialogTableVisible = true

					} else {
						this.$message.error(res.msg);
					}
				}, err => {
					console.log(err);
				})
			},
		},
	}
</script>

<style scoped>
	.bg-img {
		width: 100%;
		margin-top: 44px;
		height: auto;
	}

	.box-card {
		position: absolute;
		right: 10%;
		top: 20%;
		width: 40%;
	}

	.l-content {
		width: 450px;
		/* height: 600px; */
		position: absolute;
		right: 8%;
		top: 15%;
		bottom: 0;
		margin: auto;
	}

	.login {
		width: 100%;
		height: 100%;
		margin: auto;
		z-index: 99;
		position: relative;
	}

	.box-content {
		position: fixed;
		left: 0;
		right: 0;
		width: 40%;
		top: 50%;
		transform: translateY(-50%);
		height: 400upx;
		z-index: 1510;
		margin: auto;

	}

	.bg-con {
		position: absolute;
		width: 112px;
		height: 10px;
		bottom: 0;
		left: 0;
	}

	.text-con {
		position: relative;
		left: 0;
		z-index: 10;
	}

	.box-content .content {
		height: 350px;
		overflow: auto;
	}

	.item {
		width: 420px;
		height: 48px;
		line-height: 48px;
		background-color: #F6F7F9 !important;
		border: 0;
		border-radius: 24px;
	}


	.agreement {
		color: #3E62B1;
	}

	.login-button {
		width: 450px;
		height: 48px;
		background: #E54F42;
		line-height: 48px;
		border-radius: 24px;
	}

	.active {
		color: #111;
		font-weight: bold;
		font-size: 20px;
		border-bottom: 2px solid #E54F42;
	}

	.checked {
		color: #E54F42;
	}

	.input {
		width: 85%;
	}
</style>
